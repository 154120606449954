<template>
  <div>
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import { useStore } from 'vuex'
import { useMediaQuery } from '@vueuse/core'
import { onMounted, computed, toRaw, onBeforeMount} from 'vue'
export default {
  components: {
    Footer
  },
  setup() {
    const store = useStore()
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const address = computed(() => {
      return store.state.address
    })
    const fn = () => {
      let ethereum = window.ethereum
      ethereum.on("accountsChanged", (accounts) => {
        console.log("账号切换", accounts[0])
        if (!accounts[0]) {
          store.commit('init')
        } else {
          store.commit('getWeb3')
        }
      })
      ethereum.on("chainChanged", (chainId) => {
        console.log("用户切换了链", chainId)
        store.commit('getWeb3')
      })
    }
    onBeforeMount(() => {
      let isMobile = useMediaQuery('(max-width: 820px)')
      store.commit("setIsMobile", toRaw(isMobile.value))
      window.addEventListener('resize', () => {
        let isMobile = useMediaQuery('(max-width: 820px)')
        store.commit("setIsMobile", toRaw(isMobile.value))
      })
    })
    onMounted(() => {
      store.commit("getWeb3", true)
      fn()
    })
    return {
      web3,
      network,
      address
    }
  }
}
</script>
<style lang="scss">
#app {
  color: #2c3e50;
  background: rgb(14, 12, 14);
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
</style>
