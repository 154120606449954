<template>
  <div class="nav">
    <div class="nav-content flex-center-sb">
      <img src="@/assets/images/logo.png" alt="" class="logo">
      <div v-if="!address" class="btn-connect flex-center-center" @click="connectTestnet">Connect</div>
      <div v-else-if="address && network.chainId != 80001 && network.chainId != 56" class="btn-connect flex-center-center" @click.stop="isShowChains = !isShowChains">Switch Chain 
        <svg style="margin-left: 16px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 9L12 16L5 9" stroke="#0E0E0F" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div v-if="isShowChains" class="select">
          <div class="option flex-center" @click="connectTestnet(80001)">Mumbai</div>
          <!-- <div class="option flex-center" @click="connectTestnet(56)">Binance Chain</div> -->
        </div>
      </div>
      <div v-else class="btn-login flex-center-center">
        <div v-if="!isMobile" class="balance">{{formatBalance}}</div>
        <div v-if="!isMobile" class="line"></div>
        <div class="address flex-center" v-if="balance > 0">Please change to a bran-new wallet
          <svg v-if="!isMobile" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#E15A5A"/>
            <path d="M6.6665 13.3334L9.99984 10M9.99984 10L13.3332 6.66669M9.99984 10L6.6665 6.66669M9.99984 10L13.3332 13.3334" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-else class="address flex-center">
          {{formatAddress}}
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49268 4.98706L13.2438 1.66669L15.1656 4.9954L7.49268 4.98706Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.6665 5.83333C1.6665 5.37308 2.0396 5 2.49984 5H17.4998C17.9601 5 18.3332 5.37308 18.3332 5.83333V17.5C18.3332 17.9602 17.9601 18.3333 17.4998 18.3333H2.49984C2.0396 18.3333 1.6665 17.9602 1.6665 17.5V5.83333Z" stroke="white" stroke-width="1.25" stroke-linejoin="round"/>
            <path d="M14.6875 13.75H18.3333V9.58331H14.6875C13.4794 9.58331 12.5 10.5161 12.5 11.6666C12.5 12.8172 13.4794 13.75 14.6875 13.75Z" stroke="white" stroke-width="1.25" stroke-linejoin="round"/>
            <path d="M18.3335 6.875V16.875" stroke="white" stroke-width="1.25" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref, toRaw, watch } from "vue";
import { ethers } from "ethers";
import { addEthereumChain, walletSwitchChain } from '../libs/walletSwitchChain'
import { contract } from "../libs/connectWallet";
import { contractData, bscContractData } from '../config/contract'

export default {
  setup() {
    let isConnect = false
    let C = null
    const store = useStore()
    const balance = ref(0)
    const formatBalance = ref(0)
    const formatAddress = ref('')
    const isShowChains = ref(false)
    const web3 = computed(() => {
      return store.state.web3
    })
    const address = computed(() => {
      return store.state.address
    })
    const network = computed(() => {
      return store.state.network
    })
    const isMobile = computed(() => {
      return store.state.isMobile
    })
    const getBalance = async () => {
      try {
        let balanceEth = await C.getBalanceETH([address.value])
        balance.value = ethers.utils.formatEther(balanceEth[0])
        formatBalance.value = (+balance.value).toFixed(4)
        console.log(balance.value)
      } catch (error) {
        console.log(error)
      }
    }
    const connectTestnet = (id) => {
      if (!address.value) {
        store.commit('getWeb3')
        isConnect = true
      } else {
        isConnect = false
        let param = {
          chainId: '0x' + (+id).toString(16),
          chainName: id == 80001 ? 'Jetable Mumbai' : 'Jetable BSC',
          nativeCurrency: id == 80001 ? {
            name: 'Matic',
            symbol: 'Matic',
            decimals: 18
          } : {
            "name": "Binance Chain Native Token",
            "symbol": "BNB",
            "decimals": 18
          },
          rpcUrls: id == 80001 ? ['https://rpc.jetable.xyz/v1/mumbai/'] : ['https://rpc.jetable.xyz/v1/bsc/'],
          blockExplorerUrls: id == 80001 ? ['https://mumbai.polygonscan.com'] : ['https://bscscan.com']
        }
        addEthereumChain(param).then(async res => {
          console.log(res)
          if (network.value.chainId == id) {
            getBalance()
          } else {
            await walletSwitchChain(id)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
    watch(address, () => {
      if (address.value) {
        formatAddress.value = `${address.value.slice(0, 6)}...${address.value.slice(-6)}`
      } else {
        formatAddress.value = ''
      }
    }, { immediate: true })
    watch(web3, async (value) => {
      if ((value && network.value.chainId == 80001) || (value && network.value.chainId == 56)) {
        let user = toRaw(value).getSigner()
        let chainId = network.value.chainId
        if (chainId == 56) {
          C = await contract(bscContractData.contractAddress, bscContractData.abi, user)
        } else if (chainId == 80001) {
          C = await contract(contractData.contractAddress, contractData.abi, user)
        }
        if (isConnect) {
          connectTestnet(network.value.chainId)
        }
        getBalance()
      }
    }, {immediate: true})
    return {
      address,
      formatAddress,
      network,
      balance,
      isMobile,
      formatBalance,
      isShowChains,
      connectTestnet
    }
  },
}
</script>
<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba($color: #0E0E0F, $alpha: .8);
  backdrop-filter: blur(10px);
  .nav-content {
    max-width: 1440px;
    margin: auto;
    padding: 0 40px;
    box-sizing: border-box;
    height: 76px;
  }
  .logo {
    width: 134px;
    height: auto;
  }
  .btn-connect {
    cursor: pointer;
    padding: 0 32px;
    box-sizing: border-box;
    height: 46px;
    background: #FFFFFF;
    border-radius: 16px;
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #0E0E0F;
    transition: all .2s;
    position: relative;
    &:hover {
      background: rgba($color: #FFFFFF, $alpha: .8);
    }
    .select {
      position: absolute;
      left: 0;
      right: 0;
      top: 56px;
      background: #2D2D31;
      border: 1px solid rgba(69, 73, 83, 0.3);
      backdrop-filter: blur(18px);
      border-radius: 16px;
      padding: 6px;
      box-sizing: border-box;
      .option {
        height: 40px;
        width: 100%;
        border-radius: 12px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: #FFFFFF;
        font-family: 'Montserrat-Bold';
        padding: 0 16px;
        &:hover {
          background: #40424A;
        }
      }
    }
  }
  .btn-login {
    height: 46px;
    padding: 0 16px;
    box-sizing: border-box;
    border: 1px solid rgba(133, 141, 153, 0.33);
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
  }
  .line {
    width: 1px;
    height: 12px;
    background: rgba($color: #ffffff, $alpha: .2);
    margin: 0 12px;
  }
  .address {
    svg {
      margin-left: 12px;
    }
  }
}
@media screen and (max-width: 820px) {
  .nav {
    .nav-content {
      width: 100%;
      margin: auto;
      padding: 0 .4rem;
      box-sizing: border-box;
      height: 1.1rem;
      .logo {
        width: 1.72rem;
        height: auto;
      }
      .btn-connect {
        cursor: pointer;
        padding: 0 .64rem;
        box-sizing: border-box;
        height: .62rem;
        background: #FFFFFF;
        border-radius: .24rem;
        font-family: 'Montserrat-Bold';
        font-size: .24rem;
        line-height: 1;
        text-transform: capitalize;
        color: #0E0E0F;
      }
      .btn-login {
        height: .62rem;
        padding: 0 .2rem;
        box-sizing: border-box;
        border: 1px solid rgba(133, 141, 153, 0.33);
        border-radius: .24rem;
        font-weight: 500;
        font-size: .24rem;
        color: #FFFFFF;
      }
      .line {
        width: .02rem;
        height: .24rem;
        background: rgba($color: #ffffff, $alpha: .2);
        margin: 0 .12rem;
      }
      .address {
        svg {
          margin-left: .12rem;
          width: .2rem;
          height: auto;
        }
      }
    }
  }
}
</style>