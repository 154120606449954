<template>
  <div class="about">
    <div class="about-hd mg-center text-center">
      <div class="text" data-aos="fade-up">One More Thing</div>
      <div class="title" data-aos="fade-up" data-aos-delay="100">Jetable Wallet</div>
      <div v-if="!isMobile" class="sub-title" data-aos="fade-up" data-aos-delay="200">The revolutionary Web3 Portal with Web2 UX, empowering the next-gen <br /> Web3 projects with simplicity, speed, and dynamism.</div>
      <div v-if="isMobile" class="sub-title" data-aos="fade-up" data-aos-delay="200">The revolutionary Web3 Portal with Web2 UX, empowering the next-gen Web3 projects with simplicity, speed, and dynamism.</div>
    </div>
    <div :class="['section', isMobile ? 'flex-center-center flex-column' : 'flex-start-center']">
      <div :class="['section-l', !isMobile ? 'mt84' : '']">
        <div class="section-title" data-aos="fade-up">Simple enough for any user</div>
        <div class="section-sub-title" data-aos="fade-up" data-aos-delay="100">Gasless & fiat on-ramp integration in <br /> native assets transactions</div>
      </div>
      <div class="section-r" data-aos="fade-up" data-aos-delay="200">
        <img src="@/assets/images/about_img.png" alt="" class="img-1">
      </div>
    </div>
    <div class="section flex-center-center flex-column mt196">
      <div class="section-l pt53 text-center">
        <div class="section-title" data-aos="fade-up">DAuth SDK as a Web3 Portal</div>
        <div class="section-sub-title" data-aos="fade-up" data-aos-delay="100" :style="{'margin-top': !isMobile ? '24px' : ''}">One last mile for DApp account system</div>
      </div>
      <div class="section-r" data-aos="fade-up" data-aos-delay="200">
        <img v-if="!isMobile" src="@/assets/images/about_img2.png" alt="" class="img-2">
        <img v-else src="@/assets/images/about_img2_m.png" alt="" class="img-2">
      </div>
    </div>
    <div class="section flex-center flex-column mg-center mt267">
      <div class="section-title" data-aos="fade-up" data-aos-delay="0">Our Backers</div>
      <div class="section-sub-title" data-aos="fade-up" data-aos-delay="100" style="margin-top: 8px">We were backed, by the best.</div>
      <div class="backers">
        <div class="backers-item" data-aos="fade-up" data-aos-delay="150">
          <a href="https://ngc.fund/" target="_blank" rel="noopener noreferrer"><img src="@/assets/images/ngc.png" alt=""></a>
        </div>
        <div class="backers-item" data-aos="fade-up" data-aos-delay="200">
          <a href="https://www.aperture.xyz/" target="_blank" rel="noopener noreferrer"><img src="@/assets/images/aperture.png" alt=""></a>
        </div>
        <div class="backers-item" data-aos="fade-up" data-aos-delay="250">
          <a href="https://mowcapital.com/" target="_blank" rel="noopener noreferrer"><img src="@/assets/images/mow.png" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from "vue"
export default {
  setup() {
    const store = useStore()
    const isMobile = computed(() => {
      return store.state.isMobile
    })
    return {
      isMobile
    }
  }
}
</script>
<style lang="scss" scoped>
.about {
  padding-top: 24px;
  .about-hd {
    width: 935px;
    height: 512px;
    background: url(../assets/images/about_bg.png) no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    .text {
      font-size: 30px;
      line-height: 37px;
      font-family: 'Montserrat-ExtraBold';
      text-transform: capitalize;
      color: #FFFFFF;
    }
    .title {
      margin-top: 24px;
      text-transform: capitalize;
      background: linear-gradient(89.93deg, #4C5EFC 0.1%, #EBA8F4 62.18%, #F0C2F7 99.98%), linear-gradient(91.92deg, #7BC5F2 -11.07%, #D5B5EA 64.34%, #FF6969 161.31%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 80px;
      line-height: 98px;
      font-family: 'Montserrat-Black';
    }
    .sub-title {
      margin-top: 24px;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .section {
    margin-top: 43px;
    margin-bottom: 143px;
    .section-l {
      .section-title {
        font-size: 32px;
        line-height: 39px;
      }
    }
    .section-r {
      .img-1 {
        width: 441px;
        margin-left: 66px;
      }
      .img-2 {
        width: 729px;
        margin-top: 63px;

      }
    }
    .backers {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      font-size: 0;
      .backers-item {
        width: 220px;
        cursor: pointer;
        border-radius: 16px;
        position: relative;
        transition: all .2s;
        &:hover {
          top: -5px;
          background: linear-gradient(100.88deg, #61616B -11.36%, #3E3D48 105.71%);
        }
        & ~ .backers-item {
          margin-left: 24px;
        }
        img {
          width: 220px;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .about {
    padding-top: 0;
    .about-hd {
      width: 100%;
      height: 4.12rem;
      background: url(../assets/images/about_bg.png) no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      padding-top: .7rem;
      .text {
        font-size: .32rem;
        line-height: .4rem;
        font-family: 'Montserrat-ExtraBold';
        text-transform: capitalize;
        color: #FFFFFF;
      }
      .title {
        margin-top: .32rem;
        text-transform: capitalize;
        background: linear-gradient(89.93deg, #4C5EFC 0.1%, #EBA8F4 62.18%, #F0C2F7 99.98%), linear-gradient(91.92deg, #7BC5F2 -11.07%, #D5B5EA 64.34%, #FF6969 161.31%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: .48rem;
        line-height: .58rem;
        font-family: 'Montserrat-Black';
      }
      .sub-title {
        margin-top: .32rem;
        font-weight: 400;
        font-size: .24rem;
        line-height: .36rem;
        text-align: center;
        padding: 0 .86rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .section {
      margin-top: 1.8rem;
      margin-bottom: 2.96rem;
      flex-direction: column;
      &:last-child {
        margin-bottom: 0;
      }
      &.mt196 {
        margin-top: 0 !important;
      }
      .pt53 {
        padding-top: 0 !important;
      }
      &.mt267 {
        margin-top: 0 !important;
      }
      .section-title {
        font-size: .36rem;
        line-height: .44rem;
        text-align: center;
      }
      .section-sub-title {
        margin-top: .24rem;
        font-size: .24rem;
        line-height: .36rem;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
      }
      .section-r {
        padding: 0 .54rem;
        box-sizing: border-box;
        .img-1 {
          width: 5.16rem;
          margin-left: 0;
          margin-top: .8rem;
        }
        .img-2 {
          width: 100%;
          height: auto;
          margin-top: 1.2rem;

        }
      }
      .backers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: .64rem;
        flex-wrap: wrap;
        padding: 0 .4rem;
        .backers-item {
          width: 3.2rem;
          flex: 0 0 3.2rem;
          margin-bottom: .3rem;
          cursor: pointer;
          & ~ .backers-item {
            margin-left: 0;
          }
          img {
            width: 3.2rem;
            height: auto;
          }
        }
      }
    }
  }
}
.mt84 {
  padding-top: 84px;
}
.pt53 {
  padding-top: 53px;
}
.mt267 {
  margin-top: 267px !important;
}

.mt196 {
  margin-top: 196px !important;
}
</style>