// import { chains } from '../assets/js/chains'
export const walletSwitchChain = (chainId) => {
  chainId = '0x' + (+chainId).toString(16)
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      }).then(() => {
          console.log('网络切换成功')
          resolve()
        })
        .catch(async (e) => {
          console.log(e)
          reject(e)
        })
    }
  })
}


export const addEthereumChain = (params) => {
  return new Promise((resolve, reject) => {
    window.ethereum.request({
      id: 1,
      jsonrpc: "2.0",
      method: 'wallet_addEthereumChain',
      params: [params]
    }).then(() => {
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}