<template>
  <div class="header">
    <video class="bg" muted autoplay loop playsinline x5-video-player-type="h5">
      <source src="@/assets/images/hd_bg.mp4" />
    </video>
    <div class="content">
      <div class="title" data-aos="fade-up" data-aos-offset="50" >Fly Web3 to da </div>
      <div class="sub-title" data-aos="fade-up" data-aos-offset="50" data-aos-delay="100">Mainstream</div>
      <div class="btns flex-center" data-aos="fade-up" data-aos-offset="50" data-aos-delay="200">
        <div class="try btn-item flex-center-center" @click="tryNow">Try it now</div>
        <div class="get btn-item flex-center-center" @click="getOn">Get On 🚀️</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    tryNow() {
      this.$emit('tryNow')
    },
    getOn() {
      window.open('https://izd71nsurdp.typeform.com/to/npx1Jywb')
    }
  },
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  height: 756px;
  overflow: hidden;
  .bg {
    display: block;
    margin-left: auto;
    margin-right: 4px;
    width: 850px;
    height: auto;
  }
  .content {
    padding-top: 177px;
    position: absolute;
    top: 0;
    left: 120px;
    .title {
      font-size: 96px;
      line-height: 117px;
      letter-spacing: 0.045em;
      text-transform: capitalize;
      color: #FFFFFF;
      font-family: 'Montserrat-Black';
    }
    .sub-title {
      font-size: 96px;
      line-height: 117px;
      letter-spacing: 0.045em;
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke: 2px #FFFFFF;
      font-family: 'Montserrat-ExtraBold';
    }
    .btns {
      margin-top: 40px;
      .btn-item {
        width: 230px;
        height: 69px;
        border-radius: 25px;
        font-size: 24px;
        text-transform: capitalize;
        cursor: pointer;
        font-family: 'Montserrat-Bold';
        transition: all .2s;
        &.try {
          background: linear-gradient(94.39deg, #1A4EF5 -15.95%, #9126FB 100%);
          color: #FFFFFF;
          &:hover {
            background: linear-gradient(94.39deg, #527AFC -15.95%, #A955FD 100%);
          }
        }
        &.get {
          border: 2px solid rgba(255, 255, 255, 0.3);
          color: #FFFFFF;
          margin-left: 32px;
          &:hover {
            background: #FFFFFF;
            color: #000000;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .header {
    height: auto;
    .bg {
      width: 10rem;
      height: auto;
      margin-left: .66rem;
    }
    .content {
      padding-top: 2.08rem;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: .4rem;
      .title {
        font-size: .68rem;
        line-height: .82rem;
        letter-spacing: 0.045em;
      }
      .sub-title {
        font-size: .72rem;
        line-height: .88rem;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0);
        -webkit-text-stroke: .02rem #FFFFFF;
      }
      .btns {
        margin-top: .4rem;
        .btn-item {
          width: 2.8rem;
          height: .98rem;
          border-radius: .38rem;
          font-size: .28rem;
          text-transform: capitalize;
          cursor: pointer;
          font-family: 'Montserrat-Bold';
          &.try {
            background: linear-gradient(94.39deg, #1A4EF5 -15.95%, #9126FB 100%);
            color: #FFFFFF;
          }
          &.get {
            border: .03rem solid rgba(255, 255, 255, 1);
            color: #FFFFFF;
            margin-left: .32rem;
          }
        }
      }
    }
  }
  /* 全屏按钮 */
  video::-webkit-media-controls-fullscreen-button {
      display: none;
  }
  /* 播放按钮 */
  video::-webkit-media-controls-play-button {
      display: none;
  }
  /* 进度条 */
  video::-webkit-media-controls-timeline {
      display: none;
  }
  /* 观看的当前时间 */
  video::-webkit-media-controls-current-time-display{
      display: none;           
  }
  /* 剩余时间 */
  video::-webkit-media-controls-time-remaining-display {
      display: none;           
  }
  /* 音量按钮 */
  video::-webkit-media-controls-mute-button {
      display: none;           
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
      display: none;           
  }
  /* 音量的控制条 */
  video::-webkit-media-controls-volume-slider {
      display: none;           
  }
  /* 所有控件 */
  video::-webkit-media-controls-enclosure{
      display: none;
  }
}
</style>