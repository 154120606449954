<template>
  <div class="footer flex-center-center">
    <div v-if="!isMobile" class="title">We are here to build <br /> a new web, together</div>
    <div v-else class="title">We are here to <br /> build a new web,<br /> together</div>
    <div class="sub-title">We are looking for networks and protocols that are as <br /> dynamic as we are to buidl, grow and change with us.</div>
    <div class="btn flex-center-center" @click="getOn">Get a seat in the rocket Trip</div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from "vue";
export default {
  setup() {
    const store = useStore()
    const isMobile = computed(() => {
      return store.state.isMobile
    })
    return {
      isMobile
    }
  },

  methods: {
    getOn() {
      window.open('https://izd71nsurdp.typeform.com/to/npx1Jywb')
    }
  },
}
</script>
<style lang="scss" scoped>
.footer {
  margin-top: 192px;
  height: 680px;
  background: url(../assets/images/footer_bg.png) no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  flex-direction: column;
  .title {
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'Montserrat-Black';
  }
  .sub-title {
    margin-top: 40px;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
  }
  .btn {
    margin-top: 40px;
    width: 478px;
    height: 69px;
    background: #0E0E0F;
    border-radius: 25px;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Montserrat-Bold';
    transition: all .2s;
    &:hover {
      background: #FFFFFF;
      color: #000000;
    }
  }
}
@media screen and (max-width: 820px) {
  .footer {
    height: auto !important;
    margin-top: .94rem;
    padding: .9rem 0 .36rem;
    background: url(../assets/images/footer_bg.png) no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    flex-direction: column;
    .title {
      font-size: .48rem;
      line-height: .58rem;
      text-align: center;
      text-transform: capitalize;
      color: #FFFFFF;
      font-family: 'Montserrat-Black';
      box-sizing: border-box;
    }
    .sub-title {
      margin-top: .4rem;
      font-weight: 400;
      font-size: .24rem;
      line-height: .36rem;
      text-align: center;
      color: #FFFFFF;
    }
    .btn {
      margin-top: .4rem;
      width: 5.76rem;
      height: 1.08rem;
      background: #0E0E0F;
      border-radius: .4rem;
      font-size: .3rem;
      line-height: 1;
      text-transform: capitalize;
      color: #FFFFFF;
      cursor: pointer;
      font-family: 'Montserrat-Bold';
    }
  }
}
</style>