<template>
  <div class="main">
    <div class="section">
      <div class="section-title" data-aos-offset="0" data-aos="fade-up">Inside your Jetpack</div>
      <div class="section-sub-title" style="line-height: 19px" data-aos-offset="0" data-aos="fade-up" data-aos-delay="100"><span>BUIDL cool shit, we handle the rest.</span></div>
      <!-- <img class="section-bg" src="@/assets/images/main_bg1.png" /> -->
      <div class="scroll-item scroll-item-first" data-aos-offset="0" data-aos="fade-up" data-aos-delay="100">
        <vue3-seamless-scroll :list="list1" class="scroll img-list" direction="left" :singleLine="true" copyNum="2" :hover="true">
          <div class="item" v-for="(item, index) in list1" :key="index">
            <img style="width: 2028px" :src="item" alt="">
          </div>
        </vue3-seamless-scroll>
      </div>
      <div class="scroll-item" data-aos-offset="0" data-aos="fade-up" data-aos-delay="100">
        <vue3-seamless-scroll :list="list2" class="scroll img-list" direction="right" :singleLine="true" copyNum="2" :hover="true">
          <div class="item" v-for="(item, index) in list2" :key="index">
            <img style="width: 2560px" :src="item" alt="">
          </div>
        </vue3-seamless-scroll>
      </div>
      
    </div>
    <div class="section">
      <div class="section-title" data-aos="fade-up" >Magically Accelerating <br /> Your User Onboarding</div>
      <div class="section-sub-title" data-aos="fade-up" data-aos-delay="100">Native assets (like ETH / BNB) are the nightmare for blockchain <br /> mass adoption. Jetable helps you onboarding the wider world <br /> gaslessly and instantly with confidence.</div>
      <div class="section-step" data-aos="fade-up" data-aos-delay="200" >
        <div class="step-l" data-aos="fade-up" data-aos-delay="250">
          <div v-for="item in onboarding" :key="item.index" :class="['step-item', 'flex-center-sb', stepIndex == item.index ? 'step-item-hover' : '']" @mouseover="mouseover(item.index)" @mouseout="mouseout">
            <div class="step-item-value">{{item.title}}</div>
            <div class="step-item-index flex-center-center"><span class="flex-center-center">{{item.index}}</span>
              <svg width="39" height="49" viewBox="0 0 39 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 0.5C18.2632 0.5 21.9737 1.38496 25.3319 3.08341C28.69 4.78187 31.6017 7.24622 33.8318 10.2775C36.0619 13.3087 37.548 16.8219 38.1699 20.5334C38.7919 24.2449 38.5324 28.0506 37.4123 31.6433C36.2922 35.236 34.343 38.5149 31.722 41.2154C29.101 43.9158 25.8817 45.962 22.3241 47.1889C18.7664 48.4157 14.9701 48.7887 11.2417 48.2778C7.51334 47.7669 3.95734 46.3864 0.860859 44.2478" stroke="black"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="step-r" data-aos="fade-up" data-aos-delay="300">
          <div class="step-img flex-center-center">
            <img v-if="stepIndex == 1" src="@/assets/images/step_img1.png" alt="">
            <img v-if="stepIndex == 2" src="@/assets/images/step_img2.png" alt="">
            <img v-if="stepIndex == 3" src="@/assets/images/step_img3.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="try-section">
      <div class="section-title text-center mg-center text-center" data-aos="fade-up">Enabling Every DApp to <br/>Have Gasless Web2 UX Instantly</div>
      <div v-if="!isMobile" class="section-sub-title text-center mg-center" data-aos="fade-up" data-aos-delay="100">Easily accessible RPC-Level integration and minimizing lead to a <br/> secure, adaptable, and interconnected Web3 development platform.</div>
      <div v-else class="section-sub-title text-center mg-center text-center" data-aos="fade-up" data-aos-delay="100">Easily accessible RPC-Level integration and <br/> minimizing lead to a secure, adaptable, and <br/> interconnected Web3 development platform.</div>
      <div class="section-center flex-center-center">
        <div class="section-item" data-aos="fade-up" data-aos-delay="250">
          <div class="item-text">Start Fast with 1-Line <br /> Code Integration</div>
        </div>
        <div class="section-item" data-aos="fade-up" data-aos-delay="300">
          <div class="item-text">1-Click for Users To Get Onboard <br /> Your DApps Gaslessly</div>
          <div class="item-btn flex-center-center" @click="connectTestnet">Connect Jetable Testnet</div>
        </div>
      </div>
      <div class="connect-step flex-center-center" :style="{'min-height': isShowConnect ? '440px' : '0', 'max-height': isShowConnect ? '1440px' : '0', opacity: isShowConnect ? '1' : '0'}">
        <div v-if="connectStep == 0" class="connect-step-item flex-center-center">
          <div class="connect-step-loading flex-center-center"><span class="loader"></span></div>
          <div class="connect-step-title">loading...</div>
        </div>
        <div v-if="connectStep == 1" class="connect-step-item flex-center-center">
          <div class="connect-step-title">The metamask network addition process</div>
          <img v-if="!isMobile" src="@/assets/images/connect_step1.png" alt="" class="hint-img">
          <img v-else src="@/assets/images/connect_step1_m.png" alt="" class="hint-img">
          <div class="connect-step-btn flex-center-center" @click="connectTestnet">Switch</div>
        </div>
        <div v-if="connectStep == -1" class="connect-step-item flex-center-center">
          <div class="connect-step-title">Please delete your legacy Mumbai RPC and connect to Jetable Mumbai again</div>
          <img src="@/assets/images/connect_step3.png" alt="" class="hint-img">
          <div class="connect-step-btn flex-center-center" @click="connectTestnet">Again</div>
        </div>
        <div v-if="connectStep == 2" class="connect-step-item flex-center-center">
          <div class="connect-step-title">In Order to have a Perfect Experience, Please Switch Your Wallet To A New Address Without Balance</div>
          <div class="connect-step-sub-title">The address without balance may be simulated by jetable rpc as 1 ETH automatically</div>
          <img src="@/assets/images/connect_step2.png" alt="" class="hint-img">
        </div>
        <div v-if="connectStep == 3" :class="['connect-step-item', 'connect-step-item-mint', 'flex-start', isMobile ? 'flex-column' : 'flex-row']">
          <div class="connect-step-item-l">
            <div class="connect-step-title">Minting Jetable Rocket NFT</div>
            <div class="connect-step-sub-title" :style="{'margin-top': isMobile ? '' : '19px'}">
              <p v-if="!isMobile">We choose to go to da moon, and this is a simple start. </p>
              <p v-else>We choose to go to da moon, and this is a <br /> simple start. </p>
              <p v-if="!isMobile">Now you just switched to Polygon Mumbai powered by Jetable. Just <br /> like every newbie in Web3, supposedly, you don't have a penny 🪙 </p>
              <p v-else>Now you just switched to Polygon Mumbai powered by Jetable. Just like every newbie in Web3, supposedly, you don't have a penny 🪙 </p>
              <p>Click the Mint button, and let the magic happen.</p>
            </div>
            <div v-if="!isMobile" class="connect-step-btn flex-center-center" @click="mint">Mint</div>
          </div>
          <div class="connect-step-item-r">
            <img src="@/assets/images/nft.png" alt="">
            <div v-if="isMobile" class="connect-step-btn flex-center-center" @click="mint">Mint</div>
          </div>
        </div>
        <div v-if="connectStep == 4" class="connect-step-item connect-step-item-status flex-center-center">
          <div class="connect-step-item-hd">
            <img src="@/assets/images/flower.svg" alt="">
            <img src="@/assets/images/success.svg" alt="">
          </div>
          <div class="connect-step-title">Success!</div>
          <div class="connect-step-sub-title">You just mint your Jetable Rocket NFT gaslessly!</div>
          <div class="flex-center-center btns">
            <div class="connect-step-btn flex-center-center" @click="toOpensea">View on Opensea</div>
            <div class="connect-step-btn flex-center-center" @click="toExplorer">View On Block Explorer</div>
          </div>
        </div>
        <div v-if="connectStep == 5" class="connect-step-item connect-step-item-status flex-center-center">
          <div class="connect-step-item-hd">
            <img class="error-icon" src="@/assets/images/error.svg" alt="">
          </div>
          <div class="connect-step-title">Oops, Mint Failed.</div>
          <div class="connect-step-sub-title">Please contact us via Twitter DM.</div>
          <div class="connect-step-btn flex-center-center" @click="contact">
            <svg style="margin-right: 10px" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2833 28.0159C21.697 28.0159 27.3918 19.3855 27.3918 11.9014C27.3918 11.6563 27.3868 11.4123 27.3758 11.1694C28.4839 10.3673 29.4402 9.37415 30.1999 8.23656C29.1854 8.68773 28.0938 8.99138 26.9486 9.12829C28.1175 8.42714 29.0149 7.31791 29.4383 5.99571C28.3269 6.65523 27.1111 7.12017 25.8432 7.37046C24.8101 6.26958 23.3392 5.58105 21.7106 5.58105C18.5841 5.58105 16.0487 8.11743 16.0487 11.2439C16.0487 11.6884 16.0984 12.1206 16.1956 12.5353C11.4902 12.2985 7.31771 10.0448 4.52529 6.61788C4.02235 7.48221 3.75782 8.46453 3.75869 9.46453C3.75869 11.4295 4.75817 13.1641 6.27813 14.1789C5.37906 14.1515 4.49972 13.9085 3.71405 13.4706C3.71321 13.4943 3.71321 13.5175 3.71321 13.5429C3.71321 16.2858 5.66471 18.5758 8.25526 19.0948C7.76872 19.2273 7.26666 19.2943 6.76239 19.294C6.39822 19.294 6.0433 19.2582 5.69836 19.192C6.41914 21.4423 8.50917 23.0799 10.9872 23.1256C9.0494 24.645 6.60843 25.55 3.95545 25.55C3.50414 25.5503 3.05321 25.5241 2.60498 25.4715C5.11062 27.0781 8.08582 28.0156 11.2836 28.0156" fill="#1DA1F2"/>
            </svg>
            Contact Jetable  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let interval
import { defineComponent, ref, onMounted, watch, computed, toRaw } from "vue";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { useStore } from 'vuex'
import { ethers } from "ethers";
import { addEthereumChain, walletSwitchChain } from '../libs/walletSwitchChain'
import { contract } from "../libs/connectWallet";
import { contractData, bscContractData } from '../config/contract'
import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
export default defineComponent({
  name: 'Main',
  components: {
    Vue3SeamlessScroll
  },
  setup() {
    let isConnect = false
    let isGetBalance = false
    const store = useStore()
    const stepIndex = ref(1)
    const isShowConnect = ref(false)
    const connectStep = ref(0)
    const errorMessage = ref('')
    const list1 = ref([img1, img1, img1])
    const list2 = ref([img2, img2, img2])
    let C = null
    const isMobile = computed(() => {
      return store.state.isMobile
    })
    const address = computed(() => {
      return store.state.address
    })
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const stepChange = () => {
      if (stepIndex.value == 3) {
        stepIndex.value = 1
      } else {
        stepIndex.value += 1
      }
    }
    const getBalance = async () => {
      isGetBalance = false
      connectStep.value = 0
      try {
        console.log(C)
        let balanceEth = await C.getBalanceETH([address.value])
        let balance = ethers.utils.formatEther(balanceEth[0])
        if (balance > 0) {
          isGetBalance = true
          connectStep.value = 2
        } else {
          connectStep.value = 3
        }
        console.log(balance)
      } catch (error) {
        console.error(error)
        connectStep.value = 5
      }
    }
    const mint = async () => {
      let tx
      connectStep.value = 0
      try {
        tx = await C.mint()
      } catch (error) {
        connectStep.value = 5
        console.error(error)
        let err = error.error
        if (err.data && err.data.message) {
          errorMessage.value = err.data.message
        } else {
          errorMessage.value = err.message
        }
        return
      }
      connectStep.value = 4
      console.info('tx:', tx)
    }
    const toOpensea = () => {
      window.open(`https://testnets.opensea.io/${address.value}`)
    }
    const toExplorer = () => {
      window.open(`https://mumbai.polygonscan.com/address/${address.value}`)
    }
    const contact = () => {
      window.open('https://twitter.com/jetablexyz')
    }
    const connectTestnet = () => {
      connectStep.value = 0
      isShowConnect.value = true
      if (!address.value) {
        store.commit('getWeb3')
        isConnect = true
      } else {
        isConnect = false
        let id = network.value.chainId
        if (id != 56) id = 80001
        let param = {
          chainId: '0x' + (+id).toString(16),
          chainName: id == 80001 ? 'Jetable Mumbai' : 'Jetable BSC',
          nativeCurrency: id == 80001 ? {
            name: 'Matic',
            symbol: 'Matic',
            decimals: 18
          } : {
            "name": "Binance Chain Native Token",
            "symbol": "BNB",
            "decimals": 18
          },
          rpcUrls: id == 80001 ? ['https://rpc.jetable.xyz/v1/mumbai/'] : ['https://rpc.jetable.xyz/v1/bsc/'],
          blockExplorerUrls: id == 80001 ? ['https://mumbai.polygonscan.com'] : ['https://bscscan.com']
        }
        console.log(param)
        addEthereumChain(param).then(async res => {
          console.log(res)
          isGetBalance = true
          if (network.value.chainId == id) {
            getBalance()
          } else {
            await walletSwitchChain(id)
          }
        }).catch(err => {
          console.error(err)
          if (err.code == '-32602') {
            connectStep.value = -1  
          } else {
            connectStep.value = 1
          }
        })
        setTimeout(() => {
          document.querySelector('.connect-step').scrollIntoView({behavior: "smooth", block: "center"})
        }, 100)
      }
    }
    const tryNow = () => {
      document.querySelector('#try-section').scrollIntoView({behavior: "smooth", block: "center"})
    }
    const mouseover = (index) => {
      stepIndex.value = index
      clearInterval(interval)
    }
    const mouseout = () => {
      clearInterval(interval)
      interval = setInterval(() => {
        stepChange()
      }, 3000)
    }
    onMounted(() => {
      let el1 = document.querySelector('.section-step')
      el1.addEventListener("webkitTransitionEnd", () => {
        clearInterval(interval)
        interval = setInterval(() => {
          stepChange()
        }, 3000)
      })
      el1.addEventListener("oTransitionEnd", () => {
        clearInterval(interval)
        interval = setInterval(() => {
          stepChange()
        }, 3000)
      })
      el1.addEventListener("transitionEnd", () => {
        clearInterval(interval)
        interval = setInterval(() => {
          stepChange()
        }, 3000)
      })
    })
    watch(web3, async (value) => {
      console.log(value)
      if (value) {
        let chainId = network.value.chainId
        let user = toRaw(value).getSigner()
        if (chainId == 56) {
          C = await contract(bscContractData.contractAddress, bscContractData.abi, user)
        } else if (chainId == 80001) {
          C = await contract(contractData.contractAddress, contractData.abi, user)
        }
        
        console.log(C)
        if (isConnect) {
          connectTestnet()
        }
        if (isGetBalance) {
          getBalance()
        }
      }
    }, {immediate: true})
    return {
      list1,
      list2,
      stepIndex,
      isShowConnect,
      connectStep,
      errorMessage,
      onboarding: ref([{
        title: 'Before Jetable',
        index: 1
      }, {
        title: 'Instantly Connect to the Blockchain, Gaslessly',
        index: 2
      }, {
        title: 'The Future Is Already Here',
        index: 3
      }]),
      isMobile,
      connectTestnet,
      mint,
      toOpensea,
      tryNow,
      mouseover,
      mouseout,
      contact,
      toExplorer
    }
  },
})
</script>
<style scoped lang="scss">
.main {
  padding-top: 115px;
  box-sizing: border-box;
  .section {
    margin: auto;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 250px;
    width: 1200px;
    overflow: hidden;
    .section-title {
      font-size: 46px;
      line-height: 60px;
      text-transform: capitalize;
      color: #FFFFFF;
      font-family: 'Montserrat-Black';
    }
    .section-sub-title {
      margin-top: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.6);
      position: relative;
      z-index: 9;
      span {
        position: relative;
        z-index: 1;
      }
      &.section-sub-title-mask {
        &::after {
          content: '';
          position: absolute;
          top: -17px;
          left: -119px;
          width: 446px;
          height: 206px;
          background: linear-gradient(90deg, #0E0E0F 93.94%, rgba(14, 14, 15, 0) 100%);
        }
      }
    }
    .section-bg {
      width: 1831px;
      height: auto;
      margin-top: -13px;
    }
    .scroll-item {
      &.scroll-item-first {
        margin-top: 20px;
      }
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(14, 14, 15, 0) 0,  #0E0E0F 100%);
        width: 50px;
        z-index: 1;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, #0E0E0F 0%,rgba(14, 14, 15, 0) 100%);
        width: 50px;
        z-index: 1;
      }
      .item {
        img {
          height: 180px;
          margin-right: 32px;
          user-select: none;
        }
      }
    }
    .section-step {
      margin-top: 70px;
      background: linear-gradient(94.39deg, #1A4EF5 -15.95%, #9126FB 100%), linear-gradient(96.88deg, #262D3A -0.97%, #2F313F 111.71%);
      border: 1.5px solid #0E0E0F;
      border-radius: 54px 0px 54px 54px;
      display: flex;
      padding: 24px;
      box-sizing: border-box;
      .step-l {
        flex: 560;
        margin-right: 86px;
      }
      .step-item {
        width: 100%;
        height: 90px;
        background: #0D1541;
        backdrop-filter: blur(22px);
        border-radius: 42px;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        color: #FFFFFF;
        cursor: pointer;
        padding: 0 32px;
        box-sizing: border-box;
        transition: all .25s;
        .step-item-value {
          font-family: 'Montserrat-Bold';
        }
        & ~ .step-item {
          margin-top: 16px;
        }
        &.step-item-hover {
          background: #FFFFFF;
          color: #000000;
          .step-item-index {
            span {
              background: #000000;
              color: #FFFFFF;
            }
            svg {
              opacity: 1;
            }
          }
        }
        .step-item-index {
          position: relative;
          width: 49px;
          height: 49px;
          span {
            width: 37px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 19px;
            font-style: normal;
            font-size: 24px;
            line-height: 29px;
            text-transform: capitalize;
            color: #000000;
            font-family: 'Montserrat-ExtraBold';
          }
          svg {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
          }
        }
      }
      .step-r {
        flex: 500;
        .step-img {
          width: 100%;
          height: 440px;
          margin-top: -144px;
          background: rgba(26, 26, 31, 0.9);
          border: 1px solid rgba(69, 73, 83, 0.5);
          backdrop-filter: blur(27.5px);
          border-radius: 38px 38px 38px 0px;
          img {
            width: 73.5%;
            height: auto;
          }
        }
      }
    }
    .section-center {
      margin-top: 61px;
      .section-item {
        width: 584px;
        height: 360px;
        border: 1px solid rgba(69, 73, 83, 0.3);
        border-radius: 32px;
        padding: 80px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        background-position: 0 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &:first-child {
          background-image: url(../assets/images/card_1.png);
          margin-right: 32px;
        }
        &:last-child {
          background-image: url(../assets/images/card_2.png);
        }
        .item-text {
          font-family: 'Montserrat-SemiBold';
          font-style: normal;
          font-size: 24px;
          line-height: 36px;
          /* or 150% */
          color: #FFFFFF;
        }
        .item-img {
          height: 158px;
          width: auto;
          position: absolute;
          bottom: 0;
          left: 80px;
        }
        .item-btn {
          margin-top: 58px;
          width: 424px;
          height: 70px;
          background: #FFFFFF;
          border-radius: 25px;
          font-family: 'Montserrat-SemiBold';
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #121112;
          cursor: pointer;
          transition: all .2s;
          &:hover {
            background: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
    .connect-step {
      margin-top: 32px;
      width: 100%;
      padding: 80px;
      box-sizing: border-box;
      background: #26292E;
      border-radius: 32px;
      transition: all .3s;
      overflow: hidden;
      .connect-step-item {
        flex-direction: column;
        &.flex-row {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
        &.connect-step-item-status {
          .connect-step-item-hd {
            position: relative;
            img {
              &:first-child {
                width: 243px;
                height: auto;
              }
              &:last-child {
                position: absolute;
                left: 97px;
                top: 28px;
                width: 52px;
              }
              &.error-icon {
                position: static;
              }
            }
          }
          .connect-step-title {
            margin-top: 22px;
            font-family: 'Montserrat-SemiBold';
            font-size: 30px;
            line-height: 37px;
            text-transform: capitalize;
            color: #FFFFFF;
          }
          .connect-step-sub-title {
            margin-top: 8px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
          }
          .connect-step-btn {
            font-family: 'Montserrat-SemiBold';
            font-size: 20px;
            line-height: 24px;
            color: #121112;
            width: 300px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 25px;
            font-family: 'Montserrat-SemiBold';
            transition: all .2s;
            &:hover {
              background: rgba(255, 255, 255, 0.8);
            }
            &:last-child {
              margin-left: 32px
            }
          }
        }
        .connect-step-loading {
          margin-bottom: 32px;
          height: 280px;
          .loader {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: inline-block;
            border-top: 3px solid #FFF;
            border-right: 3px solid transparent;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          } 
        }
        .connect-step-title {
          font-family: 'Montserrat-SemiBold';
          font-size: 20px;
          line-height: 28px;
          text-transform: capitalize;
          color: #FFFFFF;
        }
        .connect-step-sub-title {
          margin-top: 12px;
          font-size: 14px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.8);
          p {
            margin-bottom: 8px;
          }
        }
        .connect-step-btn {
          margin-top: 70px;
          width: 300px;
          height: 70px;
          background: #FFFFFF;
          border-radius: 25px;
          font-family: 'Montserrat-SemiBold';
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #121112;
          cursor: pointer;
          font-family: 'Montserrat-SemiBold';
          transition: all .2s;
          &:hover {
            background: rgba(255, 255, 255, 0.8);
          }
        }
        .hint-img {
          margin-top:40px;
          height: 400px;
          width: auto;
        }
        .connect-step-item-r {
          height: 387px;
          width: 467px;
          position: relative;
          img {
            width: 467px;
            height: 467px;
            position: absolute;
            top: -40px;
            right: -40px;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
.pr120 {
  padding-right: 120px;
}
.w1300 {
  width: 1300px !important;
  .section-title {
    margin-left: 50px;
  }
  .section-sub-title {
    margin-left: 50px;
  }
}
@media screen and (max-width: 820px) {
  .main {
    padding-top: 1.1rem;
    box-sizing: border-box;
    padding: 0 .4rem;
    box-sizing: border-box;
    .section {
      margin: auto;
      box-sizing: border-box;
      position: relative;
      width: 100% !important;
      overflow: hidden;
      text-align: left;
      margin-bottom: .8rem;
      .section-title {
        font-size: .48rem;
        line-height: .6rem;
        text-transform: capitalize;
        color: #FFFFFF;
        font-family: 'Montserrat-Black';
        margin-left: 0 !important;
      }
      .section-sub-title {
        margin-top: .24rem;
        font-style: normal;
        font-weight: 400;
        font-size: .24rem;
        line-height: .3rem;
        color: rgba(255, 255, 255, 0.6);
        position: relative;
        z-index: 9;
        margin-left: 0 !important;
        span {
          position: relative;
          z-index: 1;
        }
      }
      .text-center {
        text-align: center !important;
      }
      .scroll-item {
        &.scroll-item-first {
          margin-top: .8rem;
          .item {
            img {
              width: 24.336rem !important;
              height: auto ;
            }
          }
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, rgba(14, 14, 15, 0) 0,  #0E0E0F 100%);
          width: .4rem;
          z-index: 1;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, #0E0E0F 0%,rgba(14, 14, 15, 0) 100%);
          width: .4rem;
          z-index: 1;
        }
        .item {
          img {
            width: 30.76rem !important;
            height: auto;
            margin-right: .32rem;
            user-select: none;
          }
        }
      }
      .section-step {
        margin-top: 1.98rem;
        background: linear-gradient(94.39deg, #1A4EF5 -15.95%, #9126FB 100%), linear-gradient(96.88deg, #262D3A -0.97%, #2F313F 111.71%);
        border: .3rem solid #0E0E0F;
        border-radius: .72rem 0 .72rem .72rem;
        display: flex;
        padding: .4rem;
        box-sizing: border-box;
        flex-direction: column-reverse;
        .step-l {
          width: 100%;
        }
        .step-item {
          width: 100%;
          height: 1.6rem;
          background: #0D1541;
          backdrop-filter: blur(.44rem);
          border-radius: .72rem;
          font-size: .26rem;
          line-height: .4rem;
          text-transform: capitalize;
          color: #FFFFFF;
          cursor: pointer;
          padding: 0 .4rem;
          box-sizing: border-box;
          transition: all .25s;
          .step-item-value {
            font-family: 'Montserrat-Bold';
          }
          & ~ .step-item {
            margin-top: .32rem;
          }
          &.step-item-hover {
            background: #FFFFFF;
            color: #000000;
            .step-item-index {
              span {
                background: #000000;
                color: #FFFFFF;
              }
              svg {
                opacity: 1;
              }
            }
          }
          .step-item-index {
            position: relative;
            width: .98rem;
            height: .98rem;
            flex: 0 0 .98rem;
            span {
              width: .74rem;
              height: .74rem;
              background: #FFFFFF;
              border-radius: .38rem;
              font-style: normal;
              font-size: .48rem;
              line-height: .58rem;
              text-transform: capitalize;
              color: #000000;
              font-family: 'Montserrat-ExtraBold';
            }
            svg {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              opacity: 0;
              width: .78rem;
              height: .98rem;
            }
          }
        }
        .step-r {
          .step-img {
            width: 100%;
            height: 5.2rem;
            margin-top: -1.58rem;
            background: rgba(26, 26, 31, 0.9);
            border: 1px solid rgba(69, 73, 83, 0.5);
            backdrop-filter: blur(.32rem);
            border-radius: .4rem;
            margin-bottom: .32rem;
            img {
              width: 4.7rem;
              height: auto;
            }
          }
        }
      }
      .section-center {
        margin-top: .8rem;
        flex-direction: column;
        .section-item {
          width: 100%;
          height: auto;
          border: .02rem solid rgba(69, 73, 83, 0.3);
          border-radius: .4rem;
          padding: .9rem;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;
          background-position: 0 0;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          &:first-child {
            background-image: url(../assets/images/card_1.png);
            margin-right: 0;
            margin-bottom: .32rem;
            height: 4.12rem;
          }
          &:last-child {
            background-image: url(../assets/images/card_2.png);
          }
          .item-text {
            font-family: 'Montserrat-SemiBold';
            font-style: normal;
            font-size: .28rem;
            line-height: .4rem;
            /* or 150% */
            color: #FFFFFF;
          }
          .item-img {
            height: 1.8rem;
            width: auto;
            position: absolute;
            bottom: 0;
            left: .9rem;
          }
          .item-btn {
            margin-top: .68rem;
            width: 100%;
            height: .92rem;
            background: #FFFFFF;
            border-radius: .28rem;
            font-family: 'Montserrat-SemiBold';
            font-size: .3rem;
            line-height: 1;
            text-transform: capitalize;
            color: #121112;
            cursor: pointer;
          }
        }
      }
      .connect-step {
        margin-top: .26rem;
        width: 100%;
        padding: .48rem .4rem;
        box-sizing: border-box;
        min-height: 0 !important;
        background: #26292E;
        border-radius: .4rem;
        transition: all .3s;
        overflow: hidden;
        .connect-step-item {
          flex-direction: column;
          width: 100%;
          &.flex-row {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }
          &.connect-step-item-status {
            .connect-step-item-hd {
              position: relative;
              img {
                &:first-child {
                  width: 4.02rem;
                  height: auto;
                }
                &:last-child {
                  position: absolute;
                  left: 1.62rem;
                  top: .38rem;
                  width: .96rem;
                }
                &.error-icon {
                  position: static;
                }
              }
            }
            .connect-step-title {
              margin-top: .36rem;
              font-family: 'Montserrat-SemiBold';
              font-size: .36rem;
              line-height: .44rem;
              text-transform: capitalize;
              color: #FFFFFF;
            }
            .connect-step-sub-title {
              margin-top: .16rem;
              font-weight: 500;
              font-size: .24rem;
              line-height: .36rem;
              color: #FFFFFF;
              opacity: .6;
            }
            .connect-step-btn {
              font-family: 'Montserrat-SemiBold';
              font-size: .24rem;
              line-height: 1;
              color: #121112;
              padding: 0 .1rem;
              height: .8rem;
              width: auto;
              background: #FFFFFF;
              border-radius: .28rem;
              font-family: 'Montserrat-SemiBold';
              &:last-child {
                margin-left: .2rem;
              }
            }
          }
          .connect-step-loading {
            margin-bottom: 32px;
            height: 2.5rem;
            .loader {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              display: inline-block;
              border-top: 3px solid #FFF;
              border-right: 3px solid transparent;
              box-sizing: border-box;
              animation: rotation 1s linear infinite;
            }

            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            } 
          }
          .connect-step-title {
            font-family: 'Montserrat-SemiBold';
            font-size: .28rem;
            line-height: .4rem;
            text-transform: capitalize;
            color: #FFFFFF;
            text-align: center;
          }
          .connect-step-sub-title {
            margin-top: .24rem;
            font-size: .24rem;
            text-align: center;
            line-height: .24rem;
            color: rgba(255, 255, 255, 0.8);
            p {
              margin-bottom: .08rem;
              line-height: 1.4;
              text-align: left;
            }
          }
          .connect-step-btn {
            margin-top: .5rem;
            width: 100%;
            height: 1.28rem;
            background: #FFFFFF;
            border-radius: .32rem;
            font-family: 'Montserrat-SemiBold';
            font-size: .3rem;
            line-height: 1;
            text-transform: capitalize;
            color: #121112;
            cursor: pointer;
            font-family: 'Montserrat-SemiBold';
          }
          .hint-img {
            margin-top: .4rem;
            width: 100%;
            height: auto;
          }
          .connect-step-item-r {
            width: 100%;
            height: auto;
            position: relative;
            img {
              width: 100%;
              height: auto;
              position: static;
              border-radius: .4rem;
              margin-top: .4rem;
            }
            .connect-step-btn {
              margin-top: .4rem;
            }
          }
        }
        // .connect-step-item-mint {
        //   flex-direction: column-reverse !important;
        // }
      }
    }
  }
}
</style>
