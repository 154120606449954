<template>
  <div class="home" @click="hidden">
    <Nav ref="nav" />
    <Header @tryNow="tryNow" />
    <Main ref="main" />
    <About />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Nav from '@/components/Nav.vue'
import Header from '@/components/Header.vue'
import Main from '@/components/Main.vue'
import About from '@/components/About.vue'
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: 'Home',
  components: {
    Nav,
    Header,
    Main,
    About
  },
  setup() {
    const nav = ref(null)
    const main = ref(null)
    const tryNow = () => {
      main.value.tryNow()
    }
    const hidden = () => {
      nav.value.isShowChains = false
    }
    return {
      nav,
      main,
      tryNow,
      hidden
    }
  }
})
</script>

<style lang="scss" scoped>
.home {
  padding-top: 76px;
  max-width: 1440px;
  margin: auto;
}
@media screen and (max-width: 820px) {
  .home {
    padding-top: 1.1rem;
    max-width: 850px;
    margin: auto;
  }
}
</style>