import { createStore } from 'vuex'
import { ethers } from 'ethers'
import { connectWallet } from '../libs/connectWallet'
export default createStore({
  state: {
    web3: null,
    address: '',
    balance: '',
    network: null,
    isMobile: false
  },
  mutations: {
    async getWeb3(state, init) {
      console.log(state, init)
      let web3 = await connectWallet(init)
      let accounts = await web3.listAccounts()
      let wallet_address = ''
      let balanceInEth = 0
      if (accounts.length) {
        wallet_address = accounts[0]
        try {
          web3.getBalance(wallet_address).then(res => {
            let balance = res
            balanceInEth = ethers.utils.formatEther(balance)
            state.balance = balanceInEth
          })
        } catch (error) {
          console.log(error)
        }
      }
      let network = await web3.getNetwork()
      console.log(network)
      state.web3 = web3
      state.address = wallet_address
      state.network = network
    },
    setIsMobile(state, value) {
      state.isMobile = value
    },
    init(state) {
      state.web3 = null
      state.address = ""
      state.balance = ''
      state.network = null
    }
  },
  actions: {
  },
  modules: {
  }
})
